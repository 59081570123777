import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'

const iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3125.6719615458805!2d-75.05826259999999!3d38.4259452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b8d41dcb4193c5%3A0x4d95305bc4105e34!2s103%20120th%20St%2C%20Ocean%20City%2C%20MD%2021842!5e0!3m2!1sen!2sus!4v1687199240924!5m2!1sen!2sus" width="300" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
const iframe2 = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3127.918287612591!2d-75.16156079999999!3d38.3740117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b8d806df3fffff%3A0x591b111f02c455f5!2s11022%20Nicholas%20Ln%2C%20Berlin%2C%20MD%2021811!5e0!3m2!1sen!2sus!4v1713810272420!5m2!1sen!2sus" width="300" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
function Iframe(props) {
  return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
}

const ContactPage = () => (
  <Layout>
    <Seo title="Contact OC Beach Wax" description="Contact OC Beach Wax, the #1 waxing and facials business in Ocean City, MD. " />
    <div className="row justify-content-center page-header">
        <div className="stripers">&nbsp;</div>
        <div className="col-11">
            <h1>Contact OC Beach Wax about Waxing &amp; Facials</h1>
            <p>Contact OC Beach Wax, the #1 waxing and facials business in Ocean City, MD.</p>            
        </div>        
    </div>
    <div className="row justify-content-center page-content">
        <div className="col-11 col-md-6 col-lg-4 page-section">
            <div className="page-section-text">
                <h3>Ocean City</h3>
                <p>
                103 120th Street Unit J<br />
                Ocean City, Maryland 21842<br />
                Ocean City Square<br />
                (Food Lion Shopping Center)<br />   
                <a href="tel:4105241686">410-524-1686</a>             
                </p>
                <div>
                <Iframe iframe={iframe} />
                </div>                 
            </div>
        </div>
        <div className="col-11 col-md-6 col-lg-4 page-section">
            <div className="page-section-text">
                <h3>Ocean Pines</h3>
                <p>11022 Nicholas Lane #2<br />Ocean Pines, MD 21811<br />(Business Center)<br /><a href="tel:4438801990">443-880-1990</a></p>                  
                <div>
                <Iframe iframe={iframe2} />
                </div>  
            </div>
        </div>
        <div className="col-11 col-md-12 col-lg-4 page-section">
            <div className="page-section-text">
                <h2>Like us on Social!</h2>
                <p>
                <a className="text-custom" href="https://www.facebook.com/OcBeachWax"><FontAwesomeIcon icon={faFacebook} aria-label="Facebook"  /> Facebook</a><br />
                <a className="text-custom" href="https://twitter.com/OCBeachWax"><FontAwesomeIcon icon={faTwitter} aria-label="Twitter"  /> Twitter</a>
                </p>
            <p className="text-center">
                <StaticImage      
                    className="collage-img-wrap"
                    imgClassName="collage-img img-fluid"
                    loading="eager" 
                    alt="We are hiring wax specialists"
                    src={
                    "../images/hiring.png"
                    }
                    objectFit="contain"        
                    objectPosition="50% 50%"
                    
                />
                </p>
            </div>
        </div>
        
    </div>
    
  </Layout>
)

export default ContactPage
